<template>
  <div class="app-container">
    <!-- 查询区域 -->
    <div>
      <el-form :model="queryParams" layout="inline" :inline="true">
        <el-row>
          <el-col :md="6" :sm="24" style="width: 70%">
            <el-button type="primary" @click="selectDataListButton()"
              >搜索</el-button
            >
            <el-button type="primary" @click="primaryRest()">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 按钮区 -->
    <el-row :gutter="20" style="margin: 9px 2px">
      <el-button type="success" icon="el-icon-add" size="min" @click="addButton"
        >新增产品</el-button
      >
      <el-button
        type="success"
        icon="el-icon-add"
        size="min"
        @click="editButton"
        >编辑</el-button
      >
      <el-button
        type="info"
        icon="el-icon-add"
        size="min"
        @click="delectButton()"
        >删除</el-button
      >
      <el-button
        type="info"
        icon="el-icon-add"
        size="min"
        @click="selectButton()"
        >查看</el-button
      >
    </el-row>

    <!--     列表区域   -->
    <el-table
      :data="dataColumnList"
      border
      row-key="id"
      stripe
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column label="序号" align="center" width="65">
        <template slot-scope="scope">
          <el-radio
            :label="scope.$index"
            v-model="radio"
            @change.native="getCurrentRow(scope.row)"
          ></el-radio>
        </template>
      </el-table-column>
      <el-table-column label="主键" align="center" prop="id" />
      <el-table-column label="产品编码" align="center" prop="productCode" />
      <el-table-column label="商品编码" align="center" prop="commodityCode" />
      <el-table-column label="唯一编码" align="center" prop="uniqueCode" />
      <el-table-column label="属性" align="center" prop="attribute" />
      <el-table-column label="产品名字" align="center" prop="name" />
      <el-table-column label="产品图标" align="center" prop="icon" />
      <el-table-column label="产品标题" align="center" prop="titile" />
      <el-table-column label="排序" align="center" prop="sort" />
      <el-table-column label="产品价格" align="center" prop="price" />
      <el-table-column
        label="产品状态"
        align="center"
        prop="status"
        :formatter="productStateEnum"
      />
      <el-table-column label="产品行业" align="center" prop="industry" />
      <el-table-column label="产品地址" align="center" prop="address" />
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-setting"
            v-if="scope.row.attribute == 1"
            @click="addButtonCommdity(scope.row)"
            >新增商品</el-button
          >
          <el-button
            v-if="scope.row.attribute == 2"
            size="mini"
            type="text"
            icon="el-icon-setting"
            @click="configRoleButton(scope.row)"
            >配置角色</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="queryParams.total"
      :page-size.sync="queryParams.pageSize"
      :current-page.sync="queryParams.currentPage"
    />

    <addEdit ref="addEditFrom" />
    <configRole ref="configRoleFrom" />
    <addEditCommdity ref="addEditCommdityFrom" />
  </div>
</template>


<script>
import addEdit from "./modules/addEditproduct";
import addEditCommdity from "./modules/addCommodity";
import configRole from "./modules/configRoles";
import {
  listproductApi,
} from "@/api/system/product/product.js";
export default {
  components: {
    addEdit,
    configRole,
    addEditCommdity,
  },
  data() {
    return {
      // 选中数组
      radio: null,
      // 查询参数
      queryParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      
      // 表单参数
      form: {},
      // 表单校验
      rules: {},
      optFrom: {},
      dataColumnList: [],
      statusOption: [],
      ids: null,
      systemSource: "xunCommon",
      attribute: null,
    };
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.selectDataListButton();
    },
    "queryParams.pageSize"(val) {
      this.selectDataListButton();
    },
  },
  created() {
    this.selectDataListButton();
    this.initDict();
  },
  methods: {
    selectDataListButton() {
      listproductApi(this.queryParams).then((res) => {
        this.dataColumnList = res.result.records;
        this.queryParams.total = res.result.total;
      });
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      this.ids = row.id;
      this.optFrom = row;
      this.attribute = row.attribute;
    },
    addButton() {
      this.$refs.addEditFrom.show(null, 1, 1, null);
    },
    addButtonCommdity(row) {
      this.$refs.addEditCommdityFrom.show(
        null,
        1,
        2,
        row.productCode,
        row.commodityCode
      );
    },
    editButton() {
      if (this.ids == null) return this.$message.error("请选择有效的一列");
      var attributes = this.attribute == null ? 1 : this.attribute;
      if (attributes == 1) {
        this.$refs.addEditFrom.show(this.ids, 2, attributes, null);
      }
      if (attributes == 2) {
        this.$refs.addEditCommdityFrom.show(
          this.ids,
          2,
          attributes,
          null,
          null
        );
      }
    },
    delectButton(id) {
      if (this.ids == null) return this.$message.error("请选择有效的一列");
    },
    selectButton(id) {
      if (this.ids == null) return this.$message.error("请选择有效的一列");
    },
    configRoleButton(row) {
      this.$refs.configRoleFrom.show(row.uniqueCode, row.productCode);
    },
    primaryRest() {
      this.$refs["queryParams"].resetFields();
      this.selectDataListButton();
    },
    productStateEnum(row, column) {
      return this.selectDictLabel(this.statusOption, row.status);
    },
    initDict() {
      this.selectDictItemSyn(this.systemSource, "common_status").then(
        (response) => {
          this.statusOption = response.result;
        }
      );
    },
  },
};
</script>

